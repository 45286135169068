import _ from "lodash"

export const module1 = {
    namespaced: true,
    state: () => ({
        options: {
            caseData: {},
            caseTypes: [
                {
                    id: 'oil',
                    name: 'units.type.oil',
                    isActive: false
                },
                {
                    id: 'gas',
                    name: 'units.type.gas',
                    isActive: false
                }
            ],
            fixed: {
                case: true,
                props: true,
                cos: true
            },
            resultRelevance: {
                props: false,
                cos: false
            },
            resultIterationsCount: [
                {
                    name: '1', id: 1, isActive: true
                },
                {
                    name: '1000', id: 1000, isActive: false
                },
                {
                    name: '2000', id: 2000, isActive: false
                },
                {
                    name: '5000', id: 5000, isActive: false
                }
            ],
            tabs: [
                {
                    id: 'volume',
                    name: 'units.volume',
                    isActive: false
                },
                {
                    id: 'unit',
                    name: 'units.unit',
                    isActive: false
                },
                {
                    id: 'fluid',
                    name: 'units.fluid',
                    isActive: false
                },
                {
                    id: 'cos',
                    name: 'units.risks',
                    isActive: false
                }
            ]
        },
        params: {
            settings: null,
            data: null
        },
        grv: {
            settings: null,
            data: null
        },
        cos: {
            settings: null,
            data: null
        },
        result: {
            data: {},
            id: null
        },
        tables: {
            input: null,
            output: null
        },
        showGRVResult: false
    }),
    getters: {
        allCaseData: (state) => {
            return {
                result_quantity: state.options.resultIterationsCount.find(tab => tab.isActive).id,
                data_grv: state.grv.data,
                data_params: state.params.data,
                data_cos: state.cos.data
            }
        }
    },
    mutations: {
        setCaseData(state, payload) {
            payload.caseId ? state.options.caseData.caseId = payload.caseId : false
            payload.layerId ? state.options.caseData.layerId = payload.layerId : false
            payload.projectId ? state.options.caseData.projectId = payload.projectId : false
        },
        setCaseType(state, payload) {
            state.options.caseTypes.map(item => item.id === payload ? item.isActive = true : item.isActive = false)
        },
        setActiveCaseTab(state, payload) {
            const tabId = payload

            state.options.tabs.forEach(tab => {
                tab.isActive = tab.id === tabId ? true : false
            })
        },
        updateResultRelevance(state, payload) {
            const { type, relevance } = payload

            state.options.resultRelevance[type] = relevance
        },
        updateResultIterationsCount(state, payload) {
            const count = payload

            state.options.resultIterationsCount.forEach(element => {
                if (Number(element.id) === Number(count)) {
                    element.isActive = true
                } else {
                    element.isActive = false
                }
            })
        },
        saveResult(state, payload) {
            const { result } = payload
            state.result.data = result
        },
        switchTotalVolume(state, payload) {
            state.grv.data.variant = payload
        },
        toggleFixedCase(state, payload) {
            const { fixedCase, fixedProps, fixedCOS } = payload

            if (fixedCase) {
                this.commit('module1/updateResultIterationsCount', 1)
            } else {
                const iterationsCount = state.options.resultIterationsCount.find(tab => tab.isActive).id
                this.commit('module1/updateResultIterationsCount', Number(iterationsCount) === 1 ? 1000 : iterationsCount)
            }
            state.options.fixed.case = fixedCase
            state.options.fixed.props = fixedProps
            state.options.fixed.cos = fixedCOS
        },
        setFixedCaseOption(state, payload) {
            const probabilityId = payload.probability

            let caseData = {
                grv: state.grv.data,
                params: state.params.data,
                cos: state.cos.data
            }

            const setFixedPropValue = (prop, key) => {
                if (!prop.type_param) return

                if (prop.type_param === 'distribution') {
                    prop.value = prop.multiple_values[probabilityId]
                }
                if (prop.type_param === 'deviation') {
                    if (probabilityId === 'mean') prop.value = prop.value
                    if (probabilityId === 'min') prop.value = (prop.value * (1 - prop.deviation / 100)).toFixed(2)
                    if (probabilityId === 'max') prop.value = (prop.value * (1 + prop.deviation / 100)).toFixed(2)

                    if (probabilityId.startsWith('p')) {
                        const min = Number((prop.value * (1 - prop.deviation / 100)).toFixed(2))
                        const max = Number((prop.value * (1 + prop.deviation / 100)).toFixed(2))
                        const difference = Math.abs(max - min)
                        const valueProbability = 1 - Number(probabilityId.substr(1)) / 100

                        if (prop.distribution === 'uniform') prop.value = (min + valueProbability * difference).toFixed(2)
                        if (prop.distribution === 'triangular') {
                            const F = Number(((prop.value - min) / (max - min)).toFixed(2))

                            if (valueProbability <= F) {
                                prop.value = (min + Math.sqrt( valueProbability * (max - min) * (prop.value - min))).toFixed(2)
                            } else {
                                prop.value = (max - Math.sqrt( (1 - valueProbability) * (max - min) * (max - prop.value))).toFixed(2)
                            }

                        }

                    }
                }
                prop.type_param = 'fixed'
            }

            const valueKeys = ['grv', 'area', 'average_thickness', 'thickness', 'geometric_factor', 'contact', ...Object.keys(caseData.params || []), ...Object.keys(caseData.cos || [])]

            const loopCaseData = (key) => {
                let object = _.get(caseData, key)

                for (let childKey in object) {
                    if (valueKeys.includes(childKey) && typeof object[childKey] === 'object') {
                        setFixedPropValue(object[childKey], key + childKey)
                    } else if (typeof object[childKey] === 'object') {
                        loopCaseData(`${key}.${childKey}`, key + childKey)
                    }
                }
            }

            for (let key in caseData) {
                loopCaseData(key)
            }
        },
        setGRVSettings(state, payload) {
            const rows = payload

            state.grv.settings.contact.settings.min = Math.min.apply(null, rows.map(row => row.depth)) + 1
            state.grv.settings.contact.settings.max = Math.max.apply(null, rows.map(row => row.depth))

            state.grv.settings.thickness.settings.min = 1
            state.grv.settings.thickness.settings.max = state.grv.settings.contact.settings.max - state.grv.settings.contact.settings.min
        },
        resetCaseResult(state) {
            state.result = {
                data: {},
                id: null
            }

            const caseSections = ['props', 'cos']

            caseSections.forEach(type => {
                this.commit('module1/updateResultRelevance', {
                    type: type,
                    relevance: false
                })
            })
        },
        toggleGRVResult(state, payload) {
            state.showGRVResult = payload
        }
    },
    actions: {
        async getCase({ state }, payload) {
            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/layers/${state.options.caseData.layerId}/cases/${state.options.caseData.caseId}`)

            if (error.value) return {
                error: true,
                errorMessage: error.value.data.message
            }

            const caseData = data.value

            this.commit('module1/setCaseType', caseData.type)
            this.commit("common/setLayerOptions", {
                name: data.value.layer.name,
                id: data.value.layer.id
            })
            this.commit("common/setLayerCaseOptions", {
                name: data.value.name,
                id: data.value.id,
                type: data.value.type
            })
            this.commit('module1/updateResultIterationsCount', caseData.result_quantity || 1000)

            await this.dispatch('module1/getSettings')

            if (caseData.data_grv) {
                state.grv.data = caseData.data_grv

                if (caseData.data_grv.manual.rows) {
                    this.commit('module1/setGRVSettings', caseData.data_grv.manual.rows)                    
                }
            }

            if (caseData.data_params) {
                state.params.data = caseData.data_params
            }

            if (caseData.data_cos) {
                state.cos.data = caseData.data_cos
            }

            if (caseData.actual_job) {
                this.commit('module1/saveResult', {
                    result: caseData.actual_job.result
                })

                const caseSections = ['props', 'cos']

                caseSections.forEach(type => {
                    this.commit('module1/updateResultRelevance', {
                        type: type,
                        relevance: true
                    })
                })
            }

            this.commit('module1/setActiveCaseTab', 'volume')
            this.commit('common/setLoadingStatus', false)
        },
        async getSettings({ state }, payload) {
            const { params, grv, cos } = await $fetch(`/api/projects/${state.options.caseData.projectId}/layers/${state.options.caseData.layerId}/cases/${state.options.caseData.caseId}/settings`)

            state.params.settings = params
            state.grv.settings = grv
            state.cos.settings = cos
        },
        async updatePropertyState({ state }, payload) {
            const part = payload.part
            const propId = payload.id
            const data = payload.data

            _.set(state, `${part}.${propId}`, data)

            return true
        },
        async getResult({ state, getters }) {
            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/layers/${state.options.caseData.layerId}/cases/${state.options.caseData.caseId}/calc_init`, {
                method: 'POST',
                body: JSON.stringify({
                    ...getters.allCaseData,
                    tab: state.options.tabs.find(tab => tab.isActive)?.id === 'cos' ? 'cos' : 'case'
                })
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })
                this.commit('common/setLoadingStatus', false)

                useNuxtApp().$geologyUnitCaseError(errorMessage.message, new Error())
                
                return
            }

            useGtag().gtag('event', 'start_calculation', {
				'case_type': 'geology_units' 
			})

            if (typeof ym !== 'undefined') {
                ym(useRuntimeConfig().public.YM_ID,'reachGoal','start_calculation')
            }

            try {
                state.result.id = data.value.job.id

                if (data.value.job.result && data.value.job.status === 2) {
                    if (Object.keys(data.value.job.result).length) {
                        this.commit('module1/saveResult', {
                            result: data.value.job.result
                        })
                        
                        const caseSections = ['props', 'cos']

                        caseSections.forEach(type => {
                            this.commit('module1/updateResultRelevance', {
                                type: type,
                                relevance: false
                            })
                        })
                    } else {
                        this.commit('common/setErrorData', {
                            statusCode: 500,
                            message: 'No result',
                            showError: true
                        })
                    }
                }
            } catch (e) {
                useNuxtApp().$geologyUnitCaseError('Error getting result', e)
            }
            
        },
        async getTornado({ state, getters }) {
            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/layers/${state.options.caseData.layerId}/cases/${state.options.caseData.caseId}/charts/tornado`, {
                method: 'POST', 
                body: JSON.stringify(getters.allCaseData)
            })
        
            if (error.value) {
                const errorMessage = error.value.data
        
                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                useNuxtApp().$geologyUnitCaseError(errorMessage.message, new Error())
        
                return
            }

            return data.value
        },
        async getTableInput({ state, rootState, getters }) {
            const { data, pending, error, refresh } = await useFetch(`/api/projects/${rootState.common.options.project.id}/layers/${state.options.caseData.layerId}/cases/${state.options.caseData.caseId}/props`, {
                method: 'POST', 
                body: JSON.stringify(getters.allCaseData)
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                useNuxtApp().$geologyUnitCaseError(errorMessage.message, new Error())
                
                return false
            }

            state.tables.input = data.value
        },
        async getTableOutput({ state, rootState, getters }) {
            const { data, pending, error, refresh } = await useFetch(`/api/projects/${rootState.common.options.project.id}/layers/${state.options.caseData.layerId}/cases/${state.options.caseData.caseId}/table`, {
                method: 'POST', 
                body: JSON.stringify(getters.allCaseData)
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                useNuxtApp().$geologyUnitCaseError(errorMessage.message, new Error())
                
                return false
            }

            try {
                const sortResultColumns = (object) => {
                    let original = object
                    let sortable = []

                    original.columns.filter(item => {
                        for (let key in original.result) {
                            if (item == key) {
                                return original.result[key] ? true : false
                            }
                        }
                    }).forEach(item => {
                        for (let key in original.result) {
                            if (item == key) {
                                original.result[key].id = key
                                sortable.push(original.result[key])
                            }
                        }
                    })

                    return sortable
                }

                data.value.main_table.result = sortResultColumns(data.value.main_table)
                data.value.risc_table.result = sortResultColumns(data.value.risc_table)

                state.tables.output = data.value
            } catch (e) {
                useNuxtApp().$geologyUnitCaseError('Error getting output table', e)
            }
        },
        async getCSVParse({ state }, payload) {
            let form = new FormData()
            form.append('file_input', payload)

            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/layers/${state.options.caseData.layerId}/cases/${state.options.caseData.caseId}/grv_parse`, {
                method: 'POST',
                body: form
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                useNuxtApp().$geologyUnitCaseError(errorMessage.message, new Error())
                
                return
            }

            for (let key in data.value.thickness) {
                state.grv.data.manual.thickness[key] = data.value.thickness[key]
            }
            for (let key in data.value.contact) {
                state.grv.data.manual.contact[key] = data.value.contact[key]
            }

            state.grv.data.manual.rows = data.value.rows
            state.grv.data.manual.file_name = data.value.file_name

            this.commit('module1/setGRVSettings', data.value.rows)
        }
    }
}